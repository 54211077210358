@import url("https://fonts.googleapis.com/css?family=Muli:200,200i,300,300i,400,400i,600,600i,700,700i,800,800i,900,900i");
@import "../node_modules/mapbox-gl/dist/mapbox-gl";

html {
	font-family: "Muli";
}

@media(max-width: 960px) {
	::-webkit-scrollbar {
		width: 3px;
	}
}

@media(min-width: 960px) {
	::-webkit-scrollbar {
		width: 7px;
	}
}

::-webkit-scrollbar-track {
	background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
	background: #999;
}

::-webkit-scrollbar-thumb:hover {
	background: #888;
}

label {
	cursor: pointer;
}

input:-webkit-autofill {
	box-shadow: 0 0 0 50px white inset !important;
}

// Show Eureka Modal
.ihf-eureka {
	.modal {
		&.show{
			z-index: 1301;
		}
	}
	.modal-backdrop {
		&.show {
			z-index: 1300;
		}
	}
	.ihf-modal {
		&.ihf-show{
			z-index: 1301;
		}
	}
	.ihf-modal-backdrop {
		&.ihf-show {
			z-index: 1300;
		}
	}
}

.js-plotly-plot {
	width: 100% !important;
	height: 100% !important;
}
